import { ReactElement, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import NotificationSystem from 'react-notification-system'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { setCurrentTheme } from '@junglescout/edna'
import { ErrorBoundary } from './components/ErrorBoundary'
import { Routes } from './components/Routes'

import { themeColors } from './helpers/themes'
import { sendSegmentPageEvent } from './helpers/segment'
import { usePrevious } from './hooks/previous'

import { NOTIFICATION_STYLE } from './constants/notification_styles'
import { NotificationContext } from './contexts/notification'
import { usePendo } from './hooks/pendo'

function App(): ReactElement {
  const notificationSystemRef = useRef<NotificationSystem>(null)

  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)
  const { disablePendoGuides, isPendoReady } = usePendo()

  useEffect(() => {
    setCurrentTheme('default')
  }, [])

  useEffect(() => {
    if (isPendoReady) {
      disablePendoGuides()
    }
  }, [isPendoReady])

  useEffect(() => {
    if (pathname !== prevPathname) {
      sendSegmentPageEvent(pathname, 'default', prevPathname)
    }
  }, [pathname])

  return (
    <ThemeProvider theme={themeColors}>
      <ErrorBoundary>
        <NotificationSystem
          ref={notificationSystemRef}
          style={NOTIFICATION_STYLE}
        />
        <NotificationContext.Provider value={notificationSystemRef}>
          <Routes />
        </NotificationContext.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default withTranslation()(App)
