import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import './index.css'
import App from './App'

import { checkRedirection } from './helpers/redirection'
import './i18n'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.SENTRY_RELEASE_ID || '1.0.0',
  environment: process.env.REACT_APP_JUNGLE_ENV,
})

const queryClient = new QueryClient()

const init = () => {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </React.StrictMode>,

    document.getElementById('root'),
  )
}

const willRedirect = checkRedirection()

if (!willRedirect) {
  init()
}
