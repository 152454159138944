import { lazy, ReactElement, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { ResetTokenProvider } from '../contexts/resetToken'

import { LoadingIcon } from './icons/LoadingIcon'

const StyledLoadingIcon = styled<any>(LoadingIcon)`
  position: fixed;
  top: calc(50vh - 32.5px);
  left: calc(50vw - 25px);
`

const LoginPage = lazy(() => import('./login/LoginPage'))
const ResetPasswordPage = lazy(
  () => import('./reset_password/ResetPasswordPage'),
)
const PasswordRegistrationPage = lazy(
  () => import('./password_registration/PasswordRegistrationPage'),
)

const PreCheckoutPage = lazy(() => import('./pre_checkout/PreCheckoutPage'))

export function Routes(): ReactElement {
  return (
    <Suspense fallback={<StyledLoadingIcon color="primary" size="50px" />}>
      {/* Provider strips off query-string after capturing reset token
         to prevent leaking token to 3rd parties via referer url */}
      <ResetTokenProvider>
        <Switch>
          <Route path="/register" component={PreCheckoutPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route
            path="/password-registration"
            component={PasswordRegistrationPage}
          />
          <Route path="/" component={LoginPage} />
        </Switch>
      </ResetTokenProvider>
    </Suspense>
  )
}
